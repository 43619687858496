//增强日期函数功能
var addDateFunc = function (){
	Date.prototype.format = function (format) {
		var date = {
			"M+": this.getMonth() + 1,
			"d+": this.getDate(),
			"h+": this.getHours(),
			"m+": this.getMinutes(),
			"s+": this.getSeconds(),
			"q+": Math.floor((this.getMonth() + 3) / 3),
			"S+": this.getMilliseconds()
		};
		if (/(y+)/i.test(format)) {
			format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
		}
		for (var k in date) {
			if (new RegExp("(" + k + ")").test(format)) {
				format = format.replace(RegExp.$1, RegExp.$1.length == 1
								? date[k] : ("00" + date[k]).substr(("" + date[k]).length));
			}
		}
		return format;
	}
	
	Date.prototype.addData = function(addNumber){
		addNumber = addNumber ? addNumber : 1;
		return new Date(+this + 1000*60*60*24*addNumber);
	}
	
	Date.prototype.reduceData = function(reduceNumber){
		reduceNumber = reduceNumber ? reduceNumber : 1;
		return new Date(+this - 1000*60*60*24*reduceNumber);
	}
	
	Date.prototype.addHour = function(addNumber){
		addNumber = addNumber ? addNumber : 1;
		return new Date(+this + 1000*60*60*addNumber);
	}
	
	Date.prototype.addMin = function(addNumber){
		addNumber = addNumber ? addNumber : 1;
		return new Date(+this + 1000*60*addNumber);
	}
	
	Date.prototype.reduceMin = function(reduceNumber){
		reduceNumber = reduceNumber ? reduceNumber : 1;
		return new Date(+this - 1000*60*reduceNumber);
	}
	
	Date.prototype.dataComparisonForDate= function(oData1,oData2){
		if(oData1.getTime() == oData2.getTime()){
			return 0;
		}else if(oData1.getTime() > oData2.getTime()){
			return 1;
		}else{
			return -1;
		}
	}
	
	Date.prototype.dataComparison = function(data1,data2){
		var oData1 = new Date(Date.parse(data1.replace(/-/g,"/")));
		var oData2 = new Date(Date.parse(data2.replace(/-/g,"/")));
		if(oData1.getTime() == oData2.getTime()){
			return 0;
		}else if(oData1.getTime() > oData2.getTime()){
			return 1;
		}else{
			return -1;
		}
	}
	
	Date.prototype.str2date = function(dateStr){
		return new Date(Date.parse(`${dateStr.substr(0,4)}-${dateStr.substr(4,2)}-${dateStr.substr(6,2)} ${dateStr.substr(8,2)}:${dateStr.substr(10,2)}:00`));
	}
}

exports.addDateFunc = function(){
	addDateFunc()
}