export default class Base{
    constructor(){
        this.lang={}; 
        this.init();
    }
    get(key){
        if(Object.prototype.hasOwnProperty.call(this.lang,key)){
            return this.lang[key];
        }
        return "";
    }
}