import Config from "./Config";
import Language from "./language/Language";
import DateFormat from "./DateFormat";
DateFormat.addDateFunc();

const language = new Language();

const Common = {
    toast: function (info) {
        console.log(info);
    },
    getRes: function (image) {
        return Config.resAddr + image;
    },
    getWeek: function (week) {
        const weeks = language.get("weeks");
        return weeks[week - 1];
    },
    getLanguage() {
        return language;
    },
    chechLogin(router) {
        if (!this.isLogin()) {
            router.push({
                name: 'Login'
            });
        }
    },
    isLogin() {
        var token = this.getLocal('token');
        if (token) return true;
        return false;
    },
    getFormatDate(date) {
        if (language.currentLanguage == 'ch') {
            return date.format("yyyy年MM月dd日");
        } else {
            return date.format("yyyy/MM/dd");
        }
    },
    getLanguageBtn() {
        if (language.currentLanguage == 'ch') {
            return this.getRes("language_cn_black.png");
        } else {
            return this.getRes("language_en_black.png");
        }
    },
    getLanguageHomeBtn() {
        if (language.currentLanguage == 'ch') {
            return this.getRes("language_cn_white.png");
        } else {
            return this.getRes("language_en_white.png");
        }
    },
    getCurrentLanguage() {
        return language.currentLanguage;
    },
    toggleLanguage() {
        language.toggleLanguage();
    },
    initLanguage(strList) {
        for (let key in strList) {
            strList[key] = language.get(key);
        }
    },
    setLocal(name, value) {
        localStorage.setItem(name, value);
    },
    getLocal(name) {
        return localStorage.getItem(name);
    },
    removeLocal(name) {
        localStorage.removeItem(name);
    }
}

export default Common;