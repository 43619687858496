import Ch from "./Ch";
import En from "./En";

export default class Language {
    constructor() {
        this.currentLanguage = "ch";
        this.langObject = {
            ch: new Ch(),
            en: new En(),
        }
    }

    toggleLanguage() {
        this.currentLanguage = !localStorage.getItem('Language') || localStorage.getItem('Language') == "ch" ? "en" : "ch";
        localStorage.setItem('Language', this.currentLanguage);
    }

    get(key) {
        this.currentLanguage = localStorage.getItem('Language') == null ? "ch" : localStorage.getItem('Language')
        switch (this.currentLanguage) {
            case "ch":
                return this.langObject.ch.get(key);
            case "en":
                return this.langObject.en.get(key);
        }
    }
}